import React from "react";
import PropTypes from "prop-types";
import "./ConfirmationModal.css";

const ConfirmationModal = ({
  show,
  onConfirm,
  onCancel,
  newStatus,
  comments,
  setComments,
}) => {
  if (!show) return null; // Don't render if modal is not visible
  const statusColors = {
    Pending: "#b38600",
    Selected: "#00bd2c",
    Rejected: "#c20013",
    Hold: "#00a5b8",
    UnderReview: "#ff8c00", // ✅ Fixed by adding quotes
  };
  const statusColor = statusColors[newStatus] || "blue";
  return (
    <div className="modal-overlay-confirmationmodal">
      <div className="modal-content-confirmationmodal">
        <h3
          className="modal-heading-confirmationmodal"
          style={{ whiteSpace: "nowrap" }}
        >
          Are you sure you want to change the status to "
          <span
            style={{
              color: statusColor,
              fontWeight: "bold",
              fontFamily: "Inter, sans-serif",
            }}
          >
            {newStatus}
          </span>
          "?
        </h3>
        {/* Show comments input only for Rejected and Selected statuses */}
        {(newStatus === "Rejected" ||
          newStatus === "Selected" ||
          newStatus === "Hold" ||
          newStatus === "UnderReview") && (
          <textarea
            placeholder="Comments (if any, optional)"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            className="comments-input-confirmationmodal"
          />
        )}

        <div
          className="modal-buttons-confirmationmodal"
          style={{ display: "flex", justifyContent: "center", gap: "10px" }}
        >
          <button onClick={onCancel} className="btn-red-confirmationmodal">
            Cancel
          </button>
          <button onClick={onConfirm} className="btn-green-confirmationmodal">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  newStatus: PropTypes.string.isRequired,
  comments: PropTypes.string,
  setComments: PropTypes.func.isRequired,
};

export default ConfirmationModal;
