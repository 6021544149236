import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./EmailModal.css";

const EmailModal = ({ show, onClose, selectedApplications = [] }) => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [useDropdownMessage, setUseDropdownMessage] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("");

  // Extract dynamic applicant details
  const selectedApplicant =
    selectedApplications.length > 0 ? selectedApplications[0] : null;
  const applicantName = selectedApplicant?.formData?.Name || "Applicant";
  const programTitle = "Program";

  // Hardcoded predefined email templates//
  const getPredefinedMessages = () => ({
    "Email for Selected response": `
      <p>Dear <strong>${applicantName}</strong>,</p>
      <p>We are delighted to inform you that after a thorough review of your application, you have been <strong>SELECTED</strong> for the next stage of our program.</p>
      <strong>Next Steps:</strong> Onboarding <br>
       <p>Please confirm your acceptance by responding to this email or completing the next steps mentioned above.</p>
      <p>If you have any questions, feel free to contact us at <strong>officedrishti@iiti.ac.in </strong></p>
      <p>We look forward to your participation and wish you great success!</p>
      <p><strong>Best Regards,</strong><br>
      IITI DRISHTI CPS Foundation <br>
      +91-731-660-3372</p>

          <!-- 🔹 Social Media Footer -->
    <div style="text-align: center; margin-top: 30px;">
      <p style="font-size: 14px; color: #555; margin-bottom: 10px;">Follow us for more insights</p>
      <div style="display: inline-block;">
        <a href="https://www.linkedin.com/company/iiti-drishti-cps-foundation-iit-indore/" style="margin: 0 10px; text-decoration: none;">
          <img src="https://cdn-icons-png.flaticon.com/512/733/733561.png" alt="LinkedIn" style="width: 24px; vertical-align: middle;">
        </a>
        <a href="https://twitter.com/intent/follow?screen_name=DRISHTICPS&ref_src=twsrc%5Etfw%7Ctwcamp%5Eembeddedtimeline%7Ctwterm%5Escreen-name%3ADRISHTICPS%7Ctwcon%5Es2" style="margin: 0 10px; text-decoration: none;">
          <img src="https://cdn-icons-png.flaticon.com/512/733/733579.png" alt="Twitter" style="width: 24px; vertical-align: middle;">
        </a>
        <a href="https://www.facebook.com/DrishtiCPS/" style="margin: 0 10px; text-decoration: none;">
          <img src="https://cdn-icons-png.flaticon.com/512/733/733547.png" alt="Facebook" style="width: 24px; vertical-align: middle;">
        </a>
      </div>
    </div>
    `,

    "Email for Rejected response": `
      <p>Dear <strong>${applicantName}</strong>,</p>
      <p>Thank you for applying. We appreciate the time and effort you invested in the application process.</p>
      <p>After careful evaluation, we regret to inform you that your application has not been selected for the next stage at this time. However, we encourage you to apply for future opportunities and continue striving towards your goals.</p>
      <p>If you would like feedback on your application or have any questions, please feel free to reach out to us at <strong>officedrishti@iiti.ac.in </strong></p>
      <p>We sincerely appreciate your interest and hope to connect with you again in the future.</p>
      <p><strong>Best Regards,</strong><br>
      IITI DRISHTI CPS Foundation <br>
      +91-731-660-3372</p>

          <!--  Social Media Footer -->
    <div style="text-align: center; margin-top: 30px;">
      <p style="font-size: 14px; color: #555; margin-bottom: 10px;">Follow us for more insights</p>
      <div style="display: inline-block;">
        <a href="https://www.linkedin.com/company/iiti-drishti-cps-foundation-iit-indore/" style="margin: 0 10px; text-decoration: none;">
          <img src="https://cdn-icons-png.flaticon.com/512/733/733561.png" alt="LinkedIn" style="width: 24px; vertical-align: middle;">
        </a>
        <a href="https://twitter.com/intent/follow?screen_name=DRISHTICPS&ref_src=twsrc%5Etfw%7Ctwcamp%5Eembeddedtimeline%7Ctwterm%5Escreen-name%3ADRISHTICPS%7Ctwcon%5Es2" style="margin: 0 10px; text-decoration: none;">
          <img src="https://cdn-icons-png.flaticon.com/512/733/733579.png" alt="Twitter" style="width: 24px; vertical-align: middle;">
        </a>
        <a href="https://www.facebook.com/DrishtiCPS/" style="margin: 0 10px; text-decoration: none;">
          <img src="https://cdn-icons-png.flaticon.com/512/733/733547.png" alt="Facebook" style="width: 24px; vertical-align: middle;">
        </a>
      </div>
    </div>
    `,

    "Email 3": "<p>This is the content of Email 3.</p>",
    "Email 4": "<p>This is the content of Email 4.</p>",
    "Email 5": "<p>This is the content of Email 5.</p>",
  });

  // Ensure selected applicants are "Selected" status
  const selectedApplicants = Array.isArray(selectedApplications)
    ? selectedApplications.filter(
        (app) => app.status === "Selected" && app.formData?.Email
      )
    : [];

  //   console.log("Filtered Selected Applicants:", selectedApplicants);
  // Ensure a default template is selected when using dropdown messages
  useEffect(() => {
    if (useDropdownMessage && !selectedTemplate) {
      // setSelectedTemplate("Email 1"); // Default template selection
      setSelectedTemplate("Email for Selected response"); // Default template selection
    }
  }, [useDropdownMessage, selectedTemplate]);

  const handleSendEmail = async () => {
    if (isSending) return; // Prevent multiple clicks
    // console.log("Selected Applications:", selectedApplications); // Check selected applications
    if (selectedApplications.length === 0) {
      toast.error("No applicants selected.");
      return;
    }
    const extractedEmails = selectedApplications

      .map((app) => app?.formData?.Email?.trim())
      .filter((email) => email && email.includes("@")); // Filter valid emails

    // console.log(" Extracted Emails:", extractedEmails); // Check extracted emails

    if (extractedEmails.length === 0) {
      toast.error("No valid email addresses found.");
      return;
    }

    const emailTemplates = getPredefinedMessages();
    const emailContent = useDropdownMessage
      ? emailTemplates[selectedTemplate]
      : message;

    if (!subject || !emailContent) {
      toast.error("Subject and message cannot be empty.");
      return;
    }
    setIsSending(true); //Disable buttons during sending
    try {
      const response = await axios.post(
        "https://incubator.drishticps.org/api/forms/send-email",
        {
          emails: extractedEmails,
          subject,
          message: emailContent,
        }
      );

        
      //   console.log("Email Sent Successfully:", response.data);
      toast.success("Emails sent successfully!");
      onClose();
    } catch (error) {
      console.error(
        " Email Sending Error:",
        error.response?.data || error.message
      );
      toast.error("Failed to send emails.");
    } finally {
      setIsSending(false); // Re-enable buttons after sending
    }
  };

  if (!show) return null;
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ align: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["link"],
      [{ color: [] }, { background: [] }],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "indent",
    "align",
    "script",
    "link",
    "color",
    "background",
  ];

  return (
    <div className="email-modal-overlay-emailmodal">
      <div className="email-modal-container-emailmodal">
        <button
          className="email-modal-close-button-emailmodal"
          onClick={onClose}
        >
          ×
        </button>
        <h3 className="email-modal-title-emailmodal">
          <h3 className="email-modal-main-title-emailmodal">
            Sending Email to{" "}
            <span style={{ color: "#007bff" }}>
              {selectedApplicants.length}
            </span>{" "}
            Applicants whose Review Status is "
            <span
              style={{
                color: "#00bd2c",
                fontWeight: "bold",
                fontFamily: "Inter, sans-serif",
              }}
            >
              Selected
            </span>
            "
          </h3>
        </h3>
        <div className="email-modal-content-emailmodal">
          <label className="email-modal-label-emailmodal">Subject:</label>
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder="Enter subject"
            className="email-modal-input-emailmodal"
          />

          <label className="email-modal-label-emailmodal">Message:</label>

          <div className="email-modal-toggle-container-emailmodal">
            <input
              type="checkbox"
              id="use-predefined-message"
              className="email-modal-checkbox-emailmodal"
              checked={useDropdownMessage}
              onChange={() => setUseDropdownMessage(!useDropdownMessage)}
            />
            <label
              htmlFor="use-predefined-message"
              className="email-modal-toggle-label-emailmodal"
            >
              Use Predefined Message
            </label>
          </div>

          {useDropdownMessage ? (
            <div className="email-modal-dropdown-container-emailmodal">
              <select
                className="email-modal-dropdown-emailmodal"
                value={selectedTemplate}
                onChange={(e) => setSelectedTemplate(e.target.value)}
              >
                {Object.keys(getPredefinedMessages()).map((key) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <ReactQuill
              value={message}
              onChange={setMessage}
              className="email-modal-quill-editor-emailmodal ql-container-emailmodal ql-editor-emailmodal"
              modules={modules}
              formats={formats}
              placeholder="Enter your message..."
            />
          )}
        </div>
        <div className="email-modal-buttons-emailmodal">
          <button
            className="email-modal-send-button-emailmodal"
            onClick={handleSendEmail}
            disabled={isSending}
          >
            {isSending ? "Sending..." : "Send Email"}
          </button>
          <button
            className="email-modal-cancel-button-emailmodal"
            onClick={onClose}
            disabled={isSending}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailModal;
